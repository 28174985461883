define(['app'], function (app) {
  
  const announcerHTML = `
        <div class="accessibleAnnouncer">
          <div id="accessible-announcer-assertive-1" class="srf-hide" role="log" aria-live="assertive" aria-relevant="additions" aria-atomic="true"></div>
          <div id="accessible-announcer-assertive-2" class="srf-hide" role="log" aria-live="assertive" aria-relevant="additions" aria-atomic="true"></div>
          <div id="accessible-announcer-polite-1" class="srf-hide" role="log" aria-live="polite" aria-relevant="additions" aria-atomic="true"></div>
          <div id="accessible-announcer-polite-2" class="srf-hide" role="log" aria-live="polite" aria-relevant="additions" aria-atomic="true"></div>
        </div>
    `;
  
  class AccessibleAnnouncer {
    
    constructor() {
      this.init();
    }
    
    init() {
      this.setup();
      app.subscribe('accessibility/announce', this.announce.bind(this));
    }
    
    setup() {
      this.assertive = {
        message: '',
        setAlternativeSlot: false,
        slot1: '',
        slot2: '',
        lastMessageSlot1: null,
        lastMessageSlot2: null,
      };
      
      this.polite = {
        message: '',
        setAlternativeSlot: false,
        slot1: '',
        slot2: '',
        lastMessageSlot1: null,
        lastMessageSlot2: null,
      };
      
      if (!document.querySelector('.accessibleAnnouncer')) {
        document.body.insertAdjacentHTML('beforeend', announcerHTML);
      }
    }
    
    announce(level, announcement) {
      const SELF = this;
      
      if (!document.querySelector('.accessibleAnnouncer')) {
        SELF.setup();
      }
      
      const announceObject = level === 'assertive' ? this.assertive : this.polite;
      /**
       * First check if we are going to use the same message that was last used in the target slot
       *
       * i.e. show (Slot 1), hide (Slot 2), show (Slot 1)
       * This repeat will not be announced by the screen reader due to it not being different from what was last announced
       *
       * If it is a slot repeat append '.'
       */
      const slotRepeat = !announceObject.setAlternativeSlot ? announceObject.lastMessageSlot1 === announcement : announceObject.lastMessageSlot2 === announcement;
      const announcementMessage = slotRepeat ? announcement + '.' : announcement;
      
      announceObject.slot1 = announceObject.setAlternativeSlot ? '' : announcementMessage;
      announceObject.slot2 = announceObject.setAlternativeSlot ? announcementMessage : '';
      
      document.getElementById(`accessible-announcer-${level}-1`).innerText = announceObject.slot1;
      document.getElementById(`accessible-announcer-${level}-2`).innerText = announceObject.slot2;
      
      announceObject.lastMessageSlot1 = announceObject.slot1 !== '' ? announceObject.slot1 : announceObject.lastMessageSlot1;
      announceObject.lastMessageSlot2 = announceObject.slot2 !== '' ? announceObject.slot2 : announceObject.lastMessageSlot2;
      
      announceObject.message = announcementMessage;
      
      announceObject.setAlternativeSlot = !announceObject.setAlternativeSlot;
    }
  }
  
  return new AccessibleAnnouncer();
});
